import React, { useContext } from 'react';
import { Box, Container } from '@mui/material';
import classNames from 'classnames';
import './styles.scss';
import SideBar from 'components/side-bar/SideBar';
import { UserAuthenticationContext } from 'components/providers/UserAuthenticationProvider';
import { useLocation } from 'react-router-dom';
import Footer from 'components/footer/Footer';

const MainTemplate = ({ children, ignores }) => {
    const { isLoggedIn } = useContext(UserAuthenticationContext);
    const { pathname } = useLocation();

    if (!isLoggedIn) return children;

    //do not use this any more. ok for dashboard?
    return (
        <Box className="template">
            <SideBar open={true} ignores={[]}/>
            <Box className="template-right">

                <Box overflow="auto" flex="1" maxHeight="100vh" height="100vh">
                    <Container style={{ height: '100%' }}>

                        <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
                            <Box>{children}</Box>
                            <Footer />
                        </Box>
                    </Container>
                </Box>
            </Box>
        </Box>
    );
};

export default MainTemplate;

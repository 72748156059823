import { DeckGL } from 'deck.gl';
import React, {useState} from 'react';
import tile from './layers/tilelayer'
import graph from './layers/graphlayer'
import {PathLayer} from '@deck.gl/layers';
import participants from './layers/participantlayer'
import useAnimationFrame from './../../../hooks/useAnimationFrame'

let Map = ()=>  {

    const [timer, setTimer] = useState(0);

    useAnimationFrame(deltaTime => {
        // Pass on a function to the setter of the state
        // to make sure we always have the latest state
        setTimer(prevCount => (prevCount + 1))
    })

   return <DeckGL
        controller={true}
        initialViewState={{ longitude: 4.418842792510986, latitude: 51.899256936515584, zoom: 18 }}
        layers={[
            tile(),

            participants(),

            graph({timer : timer}),

            ]}
    >

    </DeckGL>
}

export default Map;

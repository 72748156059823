import React, { useState } from 'react';
import { Mutation } from 'react-apollo';
import { Input, Button, Checkbox, TextField } from '@mui/material';
import Alert from '@mui/material/Alert';
import gql from 'graphql-tag';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FieldSet from 'components/forms/formik-fields/FieldSet';

const INSERT_DEVICE = gql`
    mutation MyMutation($id: String, $account_id: uuid, $asset_type: String, $device_type: String, $name: String) {
        insert_device_table(
            objects: {
                account_id: $account_id
                asset_type: $asset_type
                device_type: $device_type
                id: $id
                name: $name
            }
        ) {
            returning {
                id
            }
        }
    }
`;

const ViewAccountDeviceAdd = ({ account, refetch }) => {
    const [id, setId] = useState('');
    const [name, setName] = useState('');
    const [deviceType, setDeviceType] = useState('Inverter');
    const [assetType, setAssetType] = useState('Solar');
    const [error, setError] = useState(null);

    return (
        <Mutation
            mutation={INSERT_DEVICE}
            variables={{ account_id: account.id, id, name, device_type: deviceType, asset_type: assetType }}
            onCompleted={e => {
                refetch && refetch();
                setId('');
                setName('');
                setDeviceType('');
                setAssetType('');
            }}
            onError={e => {
                setError(e);
            }}
        >
            {(MyMutation, { loading, error }) => {
                return (
                    <div>
                        <TextField
                            size={'small'}
                            label={'ID'}
                            variant={'outlined'}
                            margin="dense"
                            value={id}
                            fullWidth={false}
                            onChange={e => setId(e.target.value)}
                        />

                        <TextField
                            size={'small'}
                            label={'Name'}
                            margin="dense"
                            variant={'outlined'}
                            style={{ marginLeft: '20px' }}
                            value={name}
                            fullWidth={false}
                            onChange={e => setName(e.target.value)}
                        />

                        <Select
                            labelId="demo-simple-select-label"
                            value={assetType}
                            id="deviceAssetType"
                            label="Asset Type"
                            margin="dense"
                            style={{ marginLeft: '20px' }}
                            onChange={e => setAssetType(e.target.value)}
                        >
                            <MenuItem value={'Solar'}>Solar</MenuItem>
                            <MenuItem value={'Wind'}>Wind</MenuItem>
                            <MenuItem value={'Battery'}>Battery</MenuItem>
                        </Select>

                        <Select
                            labelId="demo-simple-select-label"
                            value={deviceType}
                            label="Device Type"
                            margin="dense"
                            id="deviceType"
                            onChange={e => setDeviceType(e.target.value)}
                            style={{ marginLeft: '20px', marginRight: '20px' }}
                        >
                            <MenuItem value={'Inverter'}>Inverter</MenuItem>
                            <MenuItem value={'Meter'}>Meter</MenuItem>
                        </Select>

                        <Button
                            id={'deviceAdd'}
                            disabled={id === '' || deviceType === '' || assetType === '' || name === ''}
                            variant={'contained'}
                            onClick={() => MyMutation()}
                        >
                            {' '}
                            Add Device
                        </Button>

                        {error && error.graphQLErrors.find(e => e.extensions.code === 'constraint-violation') && (
                            <Alert severity={'error'}>You must enter a unique id </Alert>
                        )}
                        {error && error.graphQLErrors.find(e => e.extensions.code !== 'constraint-violation') && (
                            <Alert severity={'error'}>{JSON.stringify(error)} </Alert>
                        )}
                    </div>
                );
            }}
        </Mutation>
    );
};

export default ViewAccountDeviceAdd;

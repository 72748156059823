import React, {useState} from 'react';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { Box, Button } from '@mui/material';
import Map from './../map'
import './index.css'

const WelcomePage2 = () => {
    const navigation = useHistory();
    const [viewState, setViewState] = useState({
        altitude: 1.5
        ,bearing: -25.348101265822784
        ,latitude: 51.93521674899176
        ,longitude: 4.167617127870168
        ,maxPitch: 60
        ,maxZoom: 20
        ,minPitch: 0
        ,pitch: 49.42086008145234
        ,position: (3) [0, 0, 0]
        ,zoom: 11.387600614827983

    });

    return (
        <Box display="flex" flexDirection="column" alignItems="center">

            <div className="bannerTxt">

                <div className="inner">
                    <div className="panels panels-blue">
                        <a href="#" className="btns"  onClick={() => navigation.push('login')}> Login</a>
                    </div>
                </div>

            </div>

            <div className="blend fullCover">
                 <Map offset={1000} viewState={viewState} setViewState={setViewState}/>
                 <div className="overlays fullCover"></div>
            </div>


        </Box>
    );
};

export default WelcomePage2;

import React, { useEffect, useState } from 'react';
import { Button, DummySection, Layout } from './../../../components/newmap';
import useDarkMode from './../../../hooks/useDarkMode';
import WelcomePage2 from "./WelcomePage2";
import styles from './home.module.scss';
import {useHistory} from "react-router-dom";

const Home = () => {
  const [classNames] = useDarkMode(styles, ['headline__span']);

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const navigation = useHistory();

  return <WelcomePage2/>
  return (
    <Layout offset={offset}>
      <section className={styles.container}>
        <div className="wrapper">
          <div className={styles.container__blur}>

            <a href="#" className="btns"  onClick={() => navigation.push('login')}> Login</a>

            <h1 className={styles.headline}>
              Distro
            </h1>

            <h2 className={styles.subheadline}>
              This is a headline at the top
            </h2>

          </div>
        </div>
      </section>
      <div className="wrapper">

        <DummySection left={false} text={'Here is some text about something'}/>

        <DummySection left={true} text={'This would be text to display when the map is more zooomed in '}/>

      </div>
    </Layout>
  );
};

export default Home;

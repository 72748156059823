import React from 'react'
import SuperAdminAccounts from '../../components/superadmin/accounts'
import SuperAdminDeployments from '../../components/superadmin/deployments'

const SuperAdminAllDeployments = () => <div>

    <SuperAdminDeployments/>

</div>

export default SuperAdminAllDeployments;

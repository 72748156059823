import React, { Component } from 'react';
import DeckGL from '@deck.gl/react';
import {MapController, LinearInterpolator, FlyToInterpolator} from '@deck.gl/core';
import _ from "lodash";
import tile from "./tilelayer";
import {PathLayer} from "@deck.gl/layers";
import {OPERATION} from '@deck.gl/core';
import {SolidPolygonLayer} from '@deck.gl/layers';

const data = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        [
                            4.415173530578613,
                            51.8991907346044
                        ],
                        [
                            4.417791366577148,
                            51.893285131676606
                        ],
                        [
                            4.434099197387695,
                            51.89842278530546
                        ],
                        [
                            4.432210922241211,
                            51.90101787154425
                        ],
                        [
                            4.423627853393555,
                            51.90101787154425
                        ],
                        [
                            4.415173530578613,
                            51.8991907346044
                        ]
                    ]
                ]
            }
        }
    ]
};

class MapHolder extends Component {

    constructor(props) {
        super(props);
        // this.state = {};
        this.debounce  = _.debounce(e => e(), 300);
    }

    render() {

        let that = this;

        class Controller extends MapController {
            handleEvent(event) {

                super.handleEvent(event);

                if ((event.type === 'panend' || event.type === 'wheel' )) {

                    that.debounce(() => that.props.saveMap({variables : {account_id : that.props.account.id, viewstate : this.controllerState._viewportProps}}));

                }
            }
        }

        let controller = Controller;


        const rectangle = [
            [-175, 80],
            [-175, 20],
            [-50, 20],
            [-50, 80],
            [-175, 80]
        ];

        //const maskEffect = new MaskEffect();

        return (
            <div style={{position : 'relative', height: '150px', width : 'auto'}}>

                <DeckGL
                    controller={controller}
                    height="100%"
                    width="100%"
                    pickingRadius={ 10 }
                    layers={[



                        tile(),

                        new PathLayer({
                            id: 'path-layer',
                            data : data.features[0].geometry.coordinates,
                            pickable: true,
                            widthScale: 5,
                            widthMinPixels: 2,
                            getPath: d => d,
                            getColor: d => [0,134,44, 100],
                            getWidth: d => 5
                        })
                    ] }
                    onViewStateChange={(e) => {
                        this.props.setViewState(e.viewState)
                    }}
                    viewState={this.props.viewState}
                    ref={deck => {
                        this.deckGL = deck;
                    }}
                >

                </DeckGL>

            </div>
        );
    }
}

export default MapHolder;

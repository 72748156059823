import React from 'react'
import AuditList from '../../components/superadmin/audit'

const AuditPage = () => <div>

   <h1> Audit </h1>

   <AuditList/>

</div>

export default AuditPage;

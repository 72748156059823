import React from 'react'
import Wallet from '../../components/wallet'

const YourWallet = () => <div>

   <h1> Your wallet </h1>
   <Wallet/>
</div>

export default YourWallet;

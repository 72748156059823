import React from 'react'
import SuperAdminAccounts from '../../components/superadmin/accounts'
import SuperAdminDeployments from '../../components/superadmin/deployments'

const SuperAdminAllAccounts = () => <div>

    <SuperAdminAccounts/>


</div>

export default SuperAdminAllAccounts;

import React from 'react'
import FAQItem from './../../components/faq/ItemHolder';

const FAQPage = () => {

   return <div>

      <h1> FAQ </h1>

      <FAQItem title={'How do I access my data'}></FAQItem>
      <FAQItem title={'How do I review my energy trades?'}></FAQItem>

   </div>

}

export default FAQPage

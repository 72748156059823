import React from 'react'
import InvoiceList from '../../components/invoices'

const InvoicesPage = () => <div>

   <h1> Your Invoices </h1>

   <InvoiceList/>
</div>

export default InvoicesPage;

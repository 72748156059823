import React, { useContext, useState } from 'react';
import makeStyles from '@mui/styles/makeStyles';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Paper } from '@mui/material';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import ViewAccountDeviceAdd from './ViewAccountDeviceAdd'
import ViewAccountDeviceDelete from './ViewAccountDeviceDelete'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        marginTop : '10px'
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
}));

const ACCOUNT_DEVICES = gql`
    query($account_id : uuid!) {
      device_table(where: {account_id: {_eq: $account_id}}) {
        id
        device_type
        asset_type
        name
      }
    }
`;

export default function AccountDevicesQuery({ account }) {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <div>
                <Query
                    query={ACCOUNT_DEVICES}
                    variables={{ account_id: account.id }}
                    onError={() => alert('nope')}
                >
                    {({ data, refetch, loading }) => {

                        if (loading || !data) return null;

                        return (
                            <div>

                                <ViewAccountDeviceAdd account={account} refetch={refetch}/>

                                <br/>
                                <TableContainer component={Paper}>
                                    <Table className={classes.table} id={'testDevicesTable'}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{'width' : '250px'}}>ID</TableCell>
                                                <TableCell style={{'width' : '150px'}}>Name</TableCell>
                                                <TableCell style={{'width' : '150px'}}>Device Type</TableCell>
                                                <TableCell style={{'width' : '150px'}}> Asset Type</TableCell>
                                                <TableCell style={{'width' : '150px'}}> </TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {data.device_table.map((device) => {

                                                // let formsCompleted = user.forms.filter(f => f.status === 'NEW').length ===0;

                                                return (
                                                    <TableRow key={device.id}>
                                                        <TableCell component="td" scope="row">
                                                            {device.id}
                                                        </TableCell>

                                                        <TableCell component="td" scope="row">
                                                            {device.name}
                                                        </TableCell>

                                                        <TableCell component="td" scope="row">
                                                            {device.device_type}
                                                        </TableCell>

                                                        <TableCell component="td" scope="row">
                                                            {device.asset_type}
                                                        </TableCell>

                                                        <TableCell component="td" scope="row">
                                                            <ViewAccountDeviceDelete device={device} refetch={refetch}/>
                                                        </TableCell>

                                                    </TableRow>
                                                )

                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </div>
                        );
                    }}
                </Query>
            </div>
        </div>
    );
}
